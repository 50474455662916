var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          ref: "health-path-form",
          attrs: {
            id: "health-path-form",
            submitAction: _vm.isEdit ? _vm.update : _vm.create,
            submitSuccessAction: _vm.isEdit
              ? _vm.updateSuccess
              : _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("healthPath.name") },
                model: {
                  value: _vm.data.name,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "name", $$v)
                  },
                  expression: "data.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "description",
                  placeholder: _vm.$t("healthPath.description")
                },
                model: {
                  value: _vm.data.description,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "description", $$v)
                  },
                  expression: "data.description"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "notes", placeholder: _vm.$t("healthPath.notes") },
                model: {
                  value: _vm.data.notes,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "notes", $$v)
                  },
                  expression: "data.notes"
                }
              })
            ],
            1
          ),
          _vm.isEdit
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("app-numerictextbox", {
                    attrs: {
                      id: "appointmentsCount",
                      enabled: false,
                      format: "N0",
                      placeholder: _vm.$t("healthPath.appointmentsNumber")
                    },
                    model: {
                      value: _vm.data.appointmentsCount,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "appointmentsCount", $$v)
                      },
                      expression: "data.appointmentsCount"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "form-group",
              staticStyle: { margin: "4px 0 10px 1px" }
            },
            [
              _c("app-checkbox", {
                ref: "hasMovedToGym",
                attrs: {
                  id: "hasMovedToGym",
                  label: _vm.$t("healthPath.completedWithGym")
                },
                on: { change: _vm.onHasMovedToGymChanged },
                model: {
                  value: _vm.hasMovedToGym,
                  callback: function($$v) {
                    _vm.hasMovedToGym = $$v
                  },
                  expression: "hasMovedToGym"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "form-group",
              staticStyle: { margin: "6px 0 20px 1px" }
            },
            [
              _c("app-checkbox", {
                ref: "isCompleted",
                attrs: {
                  id: "isCompleted",
                  label: _vm.$t("healthPath.completedWithoutGym")
                },
                on: { change: _vm.onIsCompletedChanged },
                model: {
                  value: _vm.isCompleted,
                  callback: function($$v) {
                    _vm.isCompleted = $$v
                  },
                  expression: "isCompleted"
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: _vm.isEdit ? "shared.edit" : "shared.add",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }